import { debounce } from 'underscore';
import BaseService from '../base/baseService';

export default class Breakpointer extends BaseService {
  constructor() {
    super();
    Object.keys(window.mqObj).forEach((br) => {
      window.mqObj[br] = parseInt(window.mqObj[br].replace('px', ''), 10);
    });
    window.mqObj = Object.assign({}, { xs: 0 }, window.mqObj);
    this.breakPoints = window.mqObj;
    this.currentBreakpointValue = this.breakPoints.xs;
    this.lastBreakpointValue = this.currentBreakpointValue;
    const debounceResize = debounce(this.checkBreakpoint.bind(this), 300);
    window.addEventListener(
      'resize',
      () => {
        debounceResize();
      },
      true
    );

    window.isDesktop = () => {
      return window.innerWidth >= window.mqObj.lg;
    };
    window.isMobile = () => {
      return window.innerWidth < window.mqObj.lg;
    };
    this.checkBreakpoint();
  }

  getCurrentBreakpoint() {
    const documentWidth = window.innerWidth;
    const filtered = Object.keys(this.breakPoints).filter((key) => this.breakPoints[key] <= documentWidth);
    this.currentBreakpointValue = filtered[filtered.length - 1];
    return this.currentBreakpointValue;
  }

  checkBreakpoint() {
    this.getCurrentBreakpoint();
    if (this.lastBreakpointValue !== this.currentBreakpointValue) {
      this.EMIT(this.CUSTOM_MESSAGES.BREAKPOINTER.BREAKPOINT_CHANGE, {
        currentBreakpoint: this.currentBreakpointValue,
        breakPoint: this.breakPoints[this.currentBreakpointValue]
      });
      this.lastBreakpointValue = this.currentBreakpointValue;
      window.lastBreakpointValue = this.currentBreakpointValue;
    }
    return this.currentBreakpointValue;
  }
}
