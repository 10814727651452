import lazysizes from 'lazysizes';
import './utilities/publicPath';

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.lazyClass = 'lazyload';

const notFoundSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
document.addEventListener(
  'error',
  function(e) {
    if (e.target.nodeName.toUpperCase() == 'IMG' && e.target.src != notFoundSrc) {
      e.target.src = notFoundSrc;
      if (e.target.srcset) {
        e.target.srcset = notFoundSrc;
      }
    }
  },
  true
);
