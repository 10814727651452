import BaseService from '../base/baseService';

export default class OverlayService extends BaseService {
  constructor() {
    super();
    this.lastInteractiveElement = null;
    this.closingFunction = null;
  }

  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.INTERACTION.PUSH]: this.pushInteraction,
      [this.CUSTOM_MESSAGES.INTERACTION.REMOVE]: this.removeInteraction
    };
  }

  pushInteraction({ id, close }) {
    console.log(id);
    if (id !== this.lastInteractiveElement && this.closingFunction) this.closingFunction();
    this.lastInteractiveElement = id;
    this.closingFunction = close;
  }

  removeInteraction() {
    this.lastInteractiveElement = null;
    this.closingFunction = null;
  }
}
