/* eslint-disable no-underscore-dangle */
import CommonInterface from './commonInterface';

/**
 * This is an extract from https://github.com/giantcz/gia/blob/master/src/BaseComponent.js
 * as we create our own component that cannot be merge with unused methods on gia components.
 * In a future we could replace this template with an implementation of state full components.
 */

export default class BaseGiaComponent extends CommonInterface {
  constructor(element) {
    super();
    this._state = {};
  }
  get state() {
    return this._state;
  }

  set state(state) {
    console.warn('You should not change state manually. Use setState instead.');
  }

  setState(changes) {
    let stateChanges = {};

    Object.keys(changes).forEach((key) => {
      if (Array.isArray(changes[key])) {
        if (this._state[key] != null && Array.isArray(this._state[key])) {
          if (this._state[key].length === changes[key].length) {
            changes[key].some((item, index) => {
              if (this._state[key][index] !== item) {
                stateChanges[key] = changes[key];
                this._state[key] = stateChanges[key];
                return true;
              }
              return false;
            });
          } else {
            stateChanges[key] = changes[key];
            this._state[key] = stateChanges[key];
          }
        } else {
          stateChanges[key] = changes[key];
          this._state[key] = stateChanges[key];
        }
      } else if (typeof changes[key] === 'object') {
        if (this._state[key] != null && typeof this._state[key] === 'object') {
          stateChanges[key] = {};
          Object.keys(changes[key]).forEach((subkey) => {
            if (this._state[key][subkey] !== changes[key][subkey]) {
              stateChanges[key][subkey] = changes[key][subkey];
            }
          });
        } else {
          stateChanges[key] = changes[key];
        }

        this._state[key] = {
          ...this._state[key],
          ...stateChanges[key]
        };
      } else if (this._state[key] !== changes[key]) {
        stateChanges[key] = changes[key];

        this._state[key] = changes[key];
      }
    });

    Object.keys(stateChanges).forEach((key) => {
      if (Array.isArray(changes[key])) {
        if (stateChanges[key].length === 0) {
          delete stateChanges[key];
        }
        // eslint-disable-next-line sonarjs/no-collapsible-if
      } else if (typeof changes[key] === 'object') {
        if (Object.keys(stateChanges[key]).length === 0) {
          delete stateChanges[key];
        }
      }
    });

    this.stateChange(stateChanges);
  }

  stateChange(stateChanges) {
    // this is here only to be rewritten
  }
}
