window.mqObj = {};

// const resHandler = (obj) => {
//   for (let [key, value] of Object.entries(obj)) {
//     let mqList = window.matchMedia(`(max-width: ${value})`);
//     mqList.addListener((e) => {
//       if (e.matches) {
//         emit(CustomEvents.MQ_EVENTS[key].out);
//         console.debug(`out from ${key} breakpoint`);
//       } else {
//         emit(CustomEvents.MQ_EVENTS[key].in);
//         console.debug(`in to ${key} breakpoint`);
//       }
//     });
//   }
// };

const parseStyleToObject = (str) => {
  try {
    let styleObject = {};

    if (typeof str !== 'string') {
      return styleObject;
    }

    if (!str) {
      return styleObject;
    }

    styleObject = str.split('&').reduce((ret, param) => {
      const parts = param.replace(/\+/g, ' ').split('=');
      let key = parts[0];
      let val = parts[1];
      key = decodeURIComponent(key);

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = typeof val === 'undefined' ? null : decodeURIComponent(val);

      if (!(key in ret)) {
        ret[key] = val;
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val);
      } else {
        ret[key] = [ret[key], val];
      }
      return ret;
    }, {});

    return styleObject;
  } catch (e) {
    console.error(e);
  }
};

/**
 * @param {string} selClass
 * @returns {MqObj}
 */
const getMqFromCss = (selClass) => {
  try {
    let mediaTag = document.querySelector(`.${selClass}`);

    if (!mediaTag) {
      const head = document.querySelector('head');
      mediaTag = document.createElement('meta');
      mediaTag.classList.add(selClass);
      head.appendChild(mediaTag);
    }

    const compStyles = window.getComputedStyle(mediaTag);
    const mqStringFromCss = compStyles.getPropertyValue('font-family').replace(/"/g, '');

    return parseStyleToObject(mqStringFromCss);
  } catch (e) {
    console.error(e);
  }
};

export const ResolutionHandler = () => {
  window.mqObj = getMqFromCss('custom-mq');
};

/**
 *
 * @param {String} [max = null] - The name of the max-width breakpoint
 * @param {String} [min = null] - The name of the min-width breakpoint
 * @return {boolean}
 */
export const match = (max = null, min = null) => {
  if (!max && !min) return false;

  let maxString = '';
  let minString = '';

  if (max) {
    maxString = `(max-width: ${parseFloat(window.mqObj[max]) - 1}px)${min ? ' and ' : ''}`;
  }

  if (min) {
    minString = `(min-width: ${window.mqObj[min]}px)`;
  }

  return window.matchMedia(`${maxString}${minString}`).matches;
};

export const isTablet = () => {
  let tablet = false;
  try {
    const userAgent = window.navigator.userAgent.toLowerCase();
    tablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  } catch (error) {
    console.error(error);
  }
  return tablet;
};
