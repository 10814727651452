import BaseService from '../base/baseService';

export default class OverlayService extends BaseService {
  constructor() {
    super();
    this.$el = document.body;
    this.setState({ scroll: true, overlayLayer: false, overlayNoHeader: false, searchBar: false });
  }

  get SELECTORS() {
    return {
      header: 'header.header'
    };
  }

  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.BODY.TOGGLE_OVERLAY]: this.toggleOverlay,
      [this.CUSTOM_MESSAGES.BODY.TOGGLE_SCROLL]: this.toggleScroll
    };
  }

  /**
   * @returns {HTMLElement}
   */
  get $header() {
    if (!this._header) this._header = /** @type {HTMLElement} */ (this.$el.querySelector(this.SELECTORS.header));
    return this._header;
  }

  toggleOverlay({ overlayLayer = false, scroll = true, overlayNoHeader = false, searchBar = false }) {
    this.setState({ overlayLayer, scroll, overlayNoHeader, searchBar });
  }

  toggleScroll({ scroll = true }) {
    this.setState({ scroll });
  }

  stateChange(changes) {
    if ('scroll' in changes) {
      this.$el.classList[!changes.scroll && window.innerWidth > document.documentElement.clientWidth ? 'add' : 'remove']('has-scrollbar');
      this.$el.classList[!changes.scroll ? 'add' : 'remove']('no-scroll');
    }
    if ('overlayLayer' in changes) {
      this.$el.classList[changes.overlayLayer ? 'add' : 'remove']('overlay');
    }
    if ('overlayNoHeader' in changes) {
      this.$el.classList[changes.overlayNoHeader ? 'add' : 'remove']('overlay-no-header');
    }
    if ('searchBar' in changes) {
      // this.$el.classList[changes.searchBar ? 'add' : 'remove']('overlay-search-bar');
      this.$header.classList[changes.searchBar ? 'add' : 'remove']('overlay-search-bar');
    }
  }
}
