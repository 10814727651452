export function appPostMessage(message) {
  console.log('%c post message: ' + JSON.stringify(message), 'background: green;');
  if (window.ReactNativeWebView) {
    // Sending the message to a standard WebView
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
    return true;
  } else if (window.webkit?.messageHandlers?.ReactNativeWebView) {
    // Sending the message to a WebKit WebView
    window.webkit.messageHandlers.ReactNativeWebView.postMessage(JSON.stringify(message), '*');
    return true;
  } else if (window.webkit?.messageHandlers?.cordova_iab) {
    window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
    return true;
  }

  return false;
}
