import uuid from 'uuid/v1';
import BaseService from '../base/baseService';

export default class LoaderService extends BaseService {
  get Messages() {
    return {
      [this.CUSTOM_MESSAGES.LOADER_EVENTS.show]: this.appendLoader,
      [this.CUSTOM_MESSAGES.LOADER_EVENTS.hide]: this.removeLoader
    };
  }

  static get SELECTORS() {
    return {
      loader: '.js-loader',
      elUuid: 'data-loader'
    };
  }

  constructor() {
    super();

    this.activeLoader = {};
    this.timeoutFunction = {};
  }

  /**
   *
   * @param {String} [message]
   */
  static loaderElement(message) {
    const loaderEl = document.createElement('div');
    const dots = '<span class="loader__dot"></span><span class="loader__dot"></span><span class="loader__dot"></span>';
    loaderEl.classList.add('loader', 'js-loader');
    loaderEl.innerHTML = dots;
    if (message) LoaderService.appendMessage(message, loaderEl);

    return loaderEl;
  }

  /**
   *
   * @param {String} message
   * @param {String} [icon=icon-check]
   * @param {Boolean} [error=false]
   *
   * @returns {Element}
   */
  static messageElement(message, icon = 'icon-check', error = false) {
    const messageEl = document.createElement('div');
    messageEl.classList.add('message', 'js-message');
    if (icon) messageEl.classList.add(error ? 'icon-alert' : icon);
    if (error) messageEl.classList.add('error');
    if (!error) messageEl.classList.add('success');
    messageEl.innerText = message;

    return messageEl;
  }

  /**
   *
   * @param {{container: HTMLElement, message: String}} myObj
   */
  appendLoader({ container, message }) {
    try {
      let activeLoader = container.querySelector(LoaderService.SELECTORS.loader);
      const dataUuid = container.getAttribute(LoaderService.SELECTORS.elUuid);

      if (!dataUuid) {
        const elUuid = uuid();
        container.setAttribute(LoaderService.SELECTORS.elUuid, elUuid);
        this.activeLoader[elUuid] = [container];
      }

      if (!activeLoader) {
        container.style.overflow = 'hidden';
        // container.setAttribute('disabled', 'disabled');
        container.insertBefore(LoaderService.loaderElement(message), container.firstChild);
        activeLoader = container.querySelector(LoaderService.SELECTORS.loader);
      }
      activeLoader.classList.add('visible');
    } catch (e) {
      console.error(e);
    }
  }

  static appendMessage(message, container, icon, error) {
    const messageEl = LoaderService.messageElement(message, icon, error);
    container.appendChild(messageEl);
    messageEl.classList.add('visible');
    return messageEl;
  }

  /**
   *
   * @param {{container: HTMLElement, message: String, error: Boolean, icon: String}} myObj
   */
  removeLoader({ container, message, error, icon }) {
    try {
      const dataUuid = container.getAttribute(LoaderService.SELECTORS.elUuid);
      const activeLoader = container.querySelector(LoaderService.SELECTORS.loader);

      // non gestito per il momento
      if (message) {
        const messageEl = LoaderService.appendMessage(message, container, icon, error);
        setTimeout(() => {
          messageEl.classList.remove('visible');
        }, 1500);
        setTimeout(() => {
          messageEl.parentElement.removeChild(messageEl);
        }, 2500);
      }

      if (this.activeLoader[dataUuid] && this.activeLoader[dataUuid].length) {
        this.activeLoader[dataUuid].pop();
        if (this.activeLoader[dataUuid].length === 0) delete this.activeLoader[dataUuid];
      }

      if (activeLoader && !this.activeLoader[dataUuid]) {
        container.removeAttribute(LoaderService.SELECTORS.elUuid);
        // container.removeAttribute('disabled');
        activeLoader.classList.remove('visible');
        if (!activeLoader.classList.contains('full')) {
          // rimuovo il loader se non è il full-screen
          container.style.overflow = '';
          setTimeout(() => {
            activeLoader?.parentElement?.removeChild(activeLoader);
          }, 200);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
