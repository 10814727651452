/// <reference path="./complexTypes.d.ts" />

// hide loader on page loaded
if (window.fullLoader) {
  window.onload = () => {
    document.querySelector('body > .js-loader')?.classList.remove('visible');
  };
}

window.eventQueue = new Map();
import 'what-input';
import './core';
import './utilities/publicPath';
import './lazyload';
import { ResolutionHandler } from './utilities/resolutionHandler';
import FormValidator from './utilities/formValidator';
import Breakpointer from './services/breakpointer';
import OverlayService from './services/overlayService';
import ModalService from './services/modalService';
import LoaderService from './services/loaderService';
import SingleInteraction from './services/singleInteraction';

import 'intersection-observer';

import { ComponentDispatcher } from 'html-scoped-component-importer';
import vhCheck from 'vh-check';

vhCheck({
  bind: true,
  redefineVh: true,
  updateOnTouch: true
});

new OverlayService();
new ModalService();
new LoaderService();
new SingleInteraction();
const formValidator = new FormValidator();

const compDispatcher = new ComponentDispatcher();

compDispatcher.createAsyncComponents().then(() => {
  compDispatcher.observeDomChanges();
});

ResolutionHandler();
formValidator.start();
new Breakpointer();
